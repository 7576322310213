<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Tasks manager</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Tasks manager
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('task-manager', 'delete')"
                      @click="addTask"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                    Add new task
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                      clearable
                      dense
                      label="Search By Title"
                      outlined v-model="search.title" v-on:keyup.enter="searchTask"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-select
                      :items="priority" clearable dense
                      item-text="text"
                      item-value="value" label="Search By Priority"
                      outlined v-model="search.priority" v-on:keyup.enter="searchTask"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="addedMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          clearable
                          dense
                          label="Added Date"
                          outlined prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs" v-model="search.issued_date" v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        @input="addedMenu = false"
                        no-title
                        v-model="search.issued_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="dueDateMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          clearable
                          dense
                          label="Due Date"
                          outlined prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs" v-model="search.due_date" v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        @input="dueDateMenu = false"
                        no-title
                        v-model="search.due_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-autocomplete :items="assigned_by"
                                  :search-input.sync="assignedBySearch" chips clearable
                                  dense item-text="full_name"
                                  item-value="id" label="Select Assigned By" outlined
                                  v-model="search.assigned_by">
                    <template slot="selection" slot-scope="data">
                      {{ data.item.full_name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>

                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.full_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select Assigned By
                    </template>
                  </v-autocomplete>

                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-autocomplete :items="assigned_to"
                                  :search-input.sync="assignedToSearch" chips clearable dense
                                  item-text="full_name" item-value="id"
                                  label="Select Assigned By" multiple outlined
                                  v-model="search.assigned_to">
                    <template slot="selection" slot-scope="data">
                      {{ data.item.full_name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>

                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.full_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select Assigned To
                    </template>
                  </v-autocomplete>

                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-select
                      :items="status" clearable dense
                      item-text="text"
                      item-value="value" label="Status"
                      outlined v-model="search.status" v-on:keyup.enter="searchTask"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="8" sm="6" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchTask"
                         class="mt-1 btn btn-primary">
                    <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  type="table-row-divider@25"
                  v-if="isLoading">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Status</strong></th>
                  <th><strong>Priority</strong></th>
                  <th><strong>Applicant</strong></th>
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th><strong>Assigned By</strong></th>
                  <th><strong>Assigned To</strong></th>
                  <th><strong>Due Date</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in tasks" v-if="tasks.length > 0">
                    <td class="px-2">
                      <a @click="editTask(item.id)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.title }}
                      </a>
                    </td>
                    <td>
                      <v-btn :color="item.status == 'process' ? 'blue' : '' "
                             :outlined="item.status == 'process'"
                             class="my-1"
                             depressed rounded
                             v-if="item.status == 'process'"
                             x-small>
                        <div class="d-flex justify-content-between">
                          <v-icon class="mr-4" x-small>mdi-trending-up</v-icon>
                          <span>In Progress</span>
                        </div>
                      </v-btn>
                      <v-btn :color="item.status == 'pending' ? 'red' : '' "
                             :outlined="item.status == 'pending'"
                             class="my-1"
                             depressed rounded
                             v-if="item.status == 'pending'"
                             x-small>
                        <div class="d-flex justify-content-between">
                          <v-icon class="mr-4" x-small>fas fa-exclamation-triangle</v-icon>
                          <span>Pending</span>
                        </div>
                      </v-btn>
                      <v-btn :color="item.status == 'complete' ? 'green' : '' "
                             :outlined="item.status == 'complete'"
                             class="my-1"
                             depressed rounded
                             v-if="item.status == 'complete'"
                             x-small>
                        <div class="d-flex justify-content-between">
                          <v-icon class="mr-4" x-small>fas fa-check</v-icon>
                          <span>Completed</span>
                        </div>
                      </v-btn>
                    </td>

                    <td class="px-2">
                      <v-btn :color="item.priority == 'high' ? 'red' : '' "
                             :dark="item.priority == 'high'"
                             class="my-1"
                             depressed rounded
                             v-if="item.priority == 'high'" x-small>
                        <div class="d-flex justify-content-between">
                          <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                          <span>Important</span>
                        </div>
                      </v-btn>
                      <v-btn :color="item.priority == 'normal' ? 'blue' : '' "
                             :dark="item.priority == 'normal'"
                             class="my-1"
                             depressed rounded
                             v-if="item.priority == 'normal' " x-small>
                        <div class="d-flex justify-content-between">
                          <v-icon class="mr-4" x-small>fas fa-star</v-icon>
                          <span>Normal</span>
                        </div>
                      </v-btn>
                    </td>

                    <td class="px-2">
                        {{ item.applicant }}
                    </td>

                    <td v-if="currentUser && currentUser.access_type == 'head'">
                      {{ item.branch_name || 'N/A' }}
                    </td>

                    <td class="px-2">
                        {{ item.assigned_by_user }}
                    </td>

                    <td class="px-2">
                      <template v-if="item.assigned_to_user.length ">
                                                  <span :key="index" v-for="(user,index) in item.assigned_to_user"> {{ user }}</span>
                      </template>
                    </td>

                    <td class="px-2">
                         {{ item.due_date | moment("from", "now") }}
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            no-caret
                            no-flip
                            right
                            size="sm"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            variant="link"
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text class="navi-item" tag="div"
                                             v-if="checkIsAccessible('task-manager', 'edit')">
                              <a @click="editTask(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text class="navi-item" tag="div"
                                             v-if="checkIsAccessible('task-manager', 'delete')">
                              <a @click="deleteTask(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="tasks.length == 0">
                    <td class="text-center" colspan="6">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :per-page="perPage"
                  :total-rows="total"
                  @input="__getAll"
                  class="pull-right mt-7"
                  first-number
                  last-number
                  v-if="tasks.length > 0"
                  v-model="page"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import TaskService from "@/services/task-manager/task/TaskService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import TaskDetail from "./TaskDetail";

const adminUser = new AdminUserService();
const taskService = new TaskService();

export default {
  name: "task",
  components: {
    TaskDetail
  },
  data() {
    return {
      loading: false,
      tasks: [],
      total: null,
      perPage: null,
      page: null,
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {text: 'Status', value: 'status'},
        {text: 'Priority', value: 'priority'},
        {text: 'Applicant', value: 'applicant'},
        {text: 'Due Date', value: 'due_date'},
      ],
      priority: [
        {value: 'high', text: 'Important'},
        {value: 'normal', text: 'Normal'},
      ],
      status: [
        {value: 'pending', text: 'Pending'},
        {value: 'process', text: 'Process'},
        {value: 'complete', text: 'Complete'},
      ],
      search: {
        title: '',
        priority: '',
        status: '',
        created_at: '',
        issued_date: '',
        assigned_by: '',
        assigned_to: '',
      },
      isLoading: false,
      addedMenu: false,
      dueDateMenu: false,
      assigned_by: [],
      assigned_to: [],
      assignedBySearch: '',
      assignedToSearch: '',
    }
  },
  methods: {
    addTask() {
      this.$tabs.open({name: 'create-task-manager'});
    },

    getAllAdminUser() {
      adminUser
          .all()
          .then((response) => {
            this.assigned_by = response.data.adminUsers;
            this.assigned_to = response.data.adminUsers;
            this.checkCurrentUser();
          })
          .catch(err => {
            // console.log(err)
          })
          .finally(() => (this.isLoading = false))
    },

    editTask(id) {
      this.$tabs.open({name: 'edit-task-manager', params: {taskId: id}});
    },

    __getAll() {
      this.loading = true;
      taskService
          .paginate(this.search, this.page)
          .then(response => {
            this.tasks = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {

            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchTask() {
      this.__getAll();
    },

    checkCurrentUser() {
      this.assigned_to = this.assigned_to.filter(admin => admin.id != this.currentLoggedInUser.id)
      let assigned_to_me = this.currentLoggedInUser;
      // assigned_to_me.full_name = 'Assigned To Me';
      this.assigned_to.push(assigned_to_me);

      this.assigned_by = this.assigned_by.filter(admin => admin.id != this.currentLoggedInUser.id)
      let assigned_by_me = this.currentLoggedInUser;
      // assigned_by_me.full_name = 'Assigned By Me';
      this.assigned_by.push(assigned_by_me);

    },

    deleteTask(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            taskService
                .delete(id)
                .then((response) => {
                  this.__getAll();
                  this.$snotify.success("Task Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  watch: {
    assignedBySearch(val) {
      if (val) {
        adminUser
            .search(val)
            .then((response) => {
              this.assigned_by = response.data.admin_users;
            })
            .catch(err => {
              // console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }

    },
    assignedToSearch(val) {
      if (val) {
        adminUser
            .search(val)
            .then((response) => {
              this.assigned_to = response.data.admin_users;
            })
            .catch(err => {
              // console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }
    },
  },
  computed: {
    currentLoggedInUser() {
      return this.$store.getters.currentUser;
      ;
    }
  },
  mounted() {
    this.__getAll();
    this.getAllAdminUser();
  }
}
</script>

<style scoped>

</style>